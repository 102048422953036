import React, {useRef, useState, useEffect, Fragment} from 'react';
import classes from './SuggestionsCarousel.module.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';


function SuggestionsCarousel() {
  const [products, setProducts] = useState([]);
  const [isLoaded] = useState(true);
  const [slidePercentage, setSlidePercentage] = useState(16.66);
  const [showCart, setShowCart] = useState(false)
  const [showIndex, setShowIndex] = useState(-1);

  useEffect(()=>{

    const config = {
      headers: { Authorization: "Bearer 5|X4kcu2RRJQOFjOZxtMb16xzk5dM1hgSTKeAPS9Lp"}
    };

    axios.post('https://api.orders.e-interior.gr/api/productList',
      config
    )
    .then(function (response) {
      console.log(response.data)
      setProducts(response.data)

    })
    .catch(function (error) {
      console.log(error);
    });
    if (window.innerWidth < 600) {
      setSlidePercentage(100);
    } else if (window.innerWidth < 1200) {
      setSlidePercentage(33.33);
    } else {
      setSlidePercentage(16.66);
    }
  },[isLoaded])



  const handleCarouselItemClick = (url) =>{
    window.location.href=url;
  }
  const handleMouseOver = (index) => {
    setShowCart(true);
    setShowIndex(index);
  };
  const handleMouseOut = (event) => {
    setShowCart(false);
    setShowIndex(-1);
  };
 // 
  return (
    <div className={classes.carouselContainer}>
    {products.length>0&&
    <Fragment>
      <div className={classes.headline}>
        <p>Όσο ετοιμάζεται η παραγγελία σου ρίξε μια ματιά εδώ!</p>
        <h3>TOP ΠΡΟΣΦΟΡΕΣ</h3>
      </div>
      <Carousel className={classes.carousel} 
        showIndicators={false}
        showThumbs={false}
        showStatus={false} 
        infiniteLoop={true} 
        centerMode={true} 
        centerSlidePercentage={slidePercentage} 
        emulateTouch={true}
        selectedItem={3}>
        {products.map((product, index) => (
          <div className={classes.carouselItem} key={product.id} onClick={()=>handleCarouselItemClick(product.url)} onMouseOver={()=>handleMouseOver(index)} onMouseOut={handleMouseOut}>
            <p className={classes.percentage}><span>-{product.percentage}%</span></p>
            <img src={product.image}  height='150' alt={product.name}/>
            <div className={classes.manufacturerGrid}>
            <p className={classes.manufacturerName}>{product.manufacturer_name}</p>
            {(showCart&&showIndex===index)&& <div className={classes.cartIcon}>
                <FontAwesomeIcon icon={faShoppingCart} />
              </div>
            }
            </div>
            <div className={classes.carouselInfo}>
            <p className={classes.productName}>{product.name}</p>
              <div className={classes.carouselPrice}>
              <p className={classes.price}><s>{product.price}</s></p>
              <p className={classes.sale}>{product.sale}</p>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </Fragment>}
    </div>

  );
}

export default SuggestionsCarousel;