import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import classes from './TrackingSearch.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faShoppingCart, faClipboardCheck, faCheckDouble, faClock } from '@fortawesome/free-solid-svg-icons';
import { iconFromString } from '@fortawesome/fontawesome-svg-core';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';

function TrackingSearch(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [query, setQuery] = useState('');
  const [order, setOrder] = useState(['']);
  const [orderRender, setOrderRender] = useState();
  const [isLoaded, setIsLoaded] = useState();

  useEffect(()=>{
    const trackParameter = searchParams.get('track')
    if (trackParameter){
      setQuery(trackParameter);
      setIsLoaded(true)
    }
  },[])

  useEffect(()=>{
    // Do something with the search query, e.g. call an API
    if (isLoaded){
      axios.post('https://api.orders.e-interior.gr/api/trackOrder',{
        orderNumber:query
      }
        ).then(function (response) {
        setOrder(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  },[isLoaded])

  const mappedOrder = order.length > 0 ? order.map((item, index) => {
    let icon;
    if (item.icon == "faShoppingCart"){
      let icon = <FontAwesomeIcon icon={faShoppingCart} />
      // console.log(icon)
    }else if (item.icon == "faClipboardCheck"){
      let icon = <FontAwesomeIcon icon={faClipboardCheck} />
    }else if (item.icon == "faCheckDouble"){
      let icon = <FontAwesomeIcon icon={faCheckDouble} />
    }
    return (
      <div key={index} className={`${item.active=="0"?classes.inactive:''} ${classes.statusRow}`}>
        <div className="iconContainer">
          <span style={{backgroundColor: item.color}} className={classes.statusIcon}>
            {item.icon == "faShoppingCart" &&
            <Fragment>
            <FontAwesomeIcon icon={faShoppingCart} />
            <span className={classes.iconLine}></span>
            </Fragment>
            }
            {item.icon == "faClipboardCheck" &&
            <Fragment>
            <FontAwesomeIcon icon={faClipboardCheck} />
            <span className={classes.iconLine}></span>
            </Fragment>
            }
            {item.icon == "faClock" &&
            <Fragment>
            <FontAwesomeIcon icon={faClock} />
            <span className={classes.iconLine}></span>
            </Fragment>
            }
            {item.icon == "faCheckDouble" &&
            <Fragment>
            <FontAwesomeIcon icon={faCheckDouble} />
            </Fragment>
            }


          </span>
          
        </div>
        <div className={classes.titlesContainer}>
          <h3 className={classes.statusTitle}>{item.title}</h3>
          <p className={classes.statusMessage}>{item.message}</p>
        </div>
      </div>
      
    );
  }) : null;

  useEffect(()=>{
    setOrderRender(mappedOrder);
  },[order])

  

  function handleInputChange(event) {
    setQuery(event.target.value);
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    // Do something with the search query, e.g. call an API
    axios.post('https://api.orders.e-interior.gr/api/trackOrder',{
      orderNumber:query
    }
      ).then(function (response) {
      setOrder(response.data)
      searchParams.set('track', query);
      const queryString = searchParams.toString();
      navigate(`?${queryString}`);
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  return (
    <Fragment>
    <form className={classes.trackForm} onSubmit={handleFormSubmit}>
      <label  htmlFor="searchInput">Παρακολουθήστε την παραγγελία σας</label><br/>
      <div className={classes.trackingNumber}>
      <input
        id="searchInput"
        type="text"
        value={query}
        onChange={handleInputChange}
      />
      <button className={classes.searchButton} type="submit"><FontAwesomeIcon icon={faSearch} /></button>
      </div>
    </form>
    <div className={classes.statusTable}>
      {orderRender}
    </div>

    </Fragment>
  );
}

export default TrackingSearch;