import TrackingSearch from './components/TrackingSearch/TrackingSearch'
import SuggestionsCarousel from './components/SuggestionsCarousel/SuggestionsCarousel';
import TopLogo from './moros-logo.jpg';
import BottomLogo from './einterior-logo.jpg';
import './App.css';



function App() {
  const handleEInteriorClick = () =>{
    window.location.href="https://e-interior.gr/";
  }
  return (
    <div className="App">
      <div className="App-container">
        <img src={TopLogo} className="App-logo" alt="logo" onClick={handleEInteriorClick}/>
        <TrackingSearch/>
      </div>
      <br/>
      <div className="suggestionsContainer">
        <SuggestionsCarousel/>
      </div>
      <div className="mainLink" onClick={handleEInteriorClick}>
      <p>Δες περισσότερα εδώ</p>
      <img src={BottomLogo} className="site-logo" alt="e-interior" />
      </div>
      <div className="artivelab">
      Powered by <a href="https://artivelab.com/el" target="blank" title="Κατασκευή eshop, ιστοσελίδων και custom εφαρμογών με βάση τις ανάγκες της επιχείρησής σου!">Artivelab.com</a>
      </div>

    </div>
  );
}

export default App;
